<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="min-h-screen w-full bg-teal-900 px-6">
    <AdminHeader />

    <div class="flex justify-center w-full">
      <div class="w-full max-w-4xl px-4 md:px-8 lg:px-16 pb-8">
        <!-- Title with dot -->
        <div class="text-white text-2xl flex items-baseline mb-8">
          Error Logs
          <span class="ml-1 text-4xl leading-none" style="color: #00A388"
            >.</span
          >
        </div>

        <!-- Logs Container -->
        <div class="bg-teal-800 rounded-lg p-4 mb-8">
          <!-- Filter Options -->
          <div class="flex flex-wrap gap-4 mb-4">
            <div class="flex items-center">
              <label class="text-white mr-2">Filter by:</label>
              <div class="flex gap-2">
                <label class="flex items-center text-white">
                  <input type="checkbox" v-model="filters.error" class="mr-1" />
                  Error
                </label>
                <label class="flex items-center text-white">
                  <input type="checkbox" v-model="filters.info" class="mr-1" />
                  Info
                </label>
                <label class="flex items-center text-white">
                  <input type="checkbox" v-model="filters.debug" class="mr-1" />
                  Debug
                </label>
                <label class="flex items-center text-white">
                  <input
                    type="checkbox"
                    v-model="filters.warning"
                    class="mr-1"
                  />
                  Warning
                </label>
              </div>
            </div>
            <div class="flex items-center">
              <label class="text-white mr-2">Date Range:</label>
              <div class="flex gap-2 items-center">
                <input
                  type="date"
                  v-model="dateFilters.from"
                  class="bg-teal-900 rounded px-3 py-2 text-white"
                  :max="dateFilters.to"
                />
                <span class="text-white">to</span>
                <input
                  type="date"
                  v-model="dateFilters.to"
                  class="bg-teal-900 rounded px-3 py-2 text-white"
                  :min="dateFilters.from"
                />
                <button
                  @click="clearDateFilters"
                  class="bg-teal-700 hover:bg-teal-600 px-3 py-2 rounded text-white"
                >
                  Clear
                </button>
              </div>
            </div>
            <button
              @click="fetchAllLogs"
              class="custom-button px-4 py-2 rounded-md text-white ml-auto"
            >
              Fetch All Logs
            </button>
          </div>

          <div class="flex justify-end mb-2 text-white">
            Total Logs: {{ totalLogs }}
          </div>

          <!-- Scrollable Logs Container -->
          <div
            class="bg-teal-900 rounded-lg p-4 h-[600px] overflow-y-auto text-white font-mono text-sm"
            ref="logsContainer"
          >
            <div
              v-for="(log, index) in filteredLogs"
              :key="index"
              class="mb-2 border-b border-teal-800 pb-2"
              :class="getLogClass(log)"
            >
              <div class="flex items-start gap-2">
                <span
                  class="log-type-indicator"
                  :class="getLogTypeClass(log)"
                ></span>
                <div>{{ formatLog(log) }}</div>
              </div>
            </div>
          </div>

          <!-- Load More Button -->
          <div class="mt-4 text-center" v-if="!fetchingAll && hasMoreLogs">
            <button
              @click="loadMoreLogs"
              class="custom-button px-4 py-2 rounded-md text-white"
            >
              Load More
            </button>
          </div>
        </div>

        <!-- Back Button -->
        <button
          @click="$router.back()"
          class="mt-4 bg-teal-800 gap-5 text-white px-6 py-4 rounded-md flex items-center space-x-2"
          style="background-color: #204952"
        >
          <span class="material-icons text-xl arrow-left-icon">arrow_left</span>
          <span>Back</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "../../components/AdminHeader.vue";

import Loading from "vue-loading-overlay";

export default {
  name: "Logs",
  components: {
    AdminHeader,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      logs: [],
      currentPage: 1,
      totalLogs: 0,
      fetchingAll: false,
      filters: {
        error: true,
        info: true,
        debug: true,
        warning: true,
      },
      dateFilters: {
        from: "",
        to: "",
      },
    };
  },
  computed: {
    hasMoreLogs() {
      return this.logs.length < this.totalLogs;
    },
    filteredLogs() {
      return this.logs.filter((log) => {
        const logType = this.getLogType(log);
        const typeMatch = this.filters[logType.toLowerCase()];

        const dateMatch = this.checkDateFilter(log);

        return typeMatch && dateMatch;
      });
    },
  },
  mounted() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      if (
        (this.dateFilters.from && !this.dateFilters.to) ||
        (!this.dateFilters.from && this.dateFilters.to)
      ) {
        return;
      }

      this.isLoading = true;
      try {
        const params = {
          page: this.currentPage,
        };

        if (this.dateFilters.from && this.dateFilters.to) {
          params.from = this.dateFilters.from;
          params.to = this.dateFilters.to;
        }

        const response = await axios.get(`/error/logs`, {
          params,
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });
        this.logs = response.data.logs;
        this.totalLogs = response.data.total;
      } catch (error) {
        console.error("Error fetching logs:", error);
        this.$swal.fire({
          icon: "error",
          title: "Error fetching logs",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async loadMoreLogs() {
      if (
        (this.dateFilters.from && !this.dateFilters.to) ||
        (!this.dateFilters.from && this.dateFilters.to)
      ) {
        return;
      }

      this.currentPage++;
      try {
        const params = {
          page: this.currentPage,
        };

        if (this.dateFilters.from && this.dateFilters.to) {
          params.from = this.dateFilters.from;
          params.to = this.dateFilters.to;
        }

        const response = await axios.get(`/error/logs`, {
          params,
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });
        this.logs = [...this.logs, ...response.data.logs];
      } catch (error) {
        console.error("Error loading more logs:", error);
        this.currentPage--;
      }
    },

    async fetchAllLogs() {
      this.isLoading = true;
      this.fetchingAll = true;
      try {
        const response = await axios.get(`/error/logs`, {
          params: { all: true },
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("access")).access_token
            }`,
          },
        });
        this.logs = response.data.logs;
        this.totalLogs = response.data.total;
      } catch (error) {
        console.error("Error fetching all logs:", error);
        this.$swal.fire({
          icon: "error",
          title: "Error fetching all logs",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
        });
      } finally {
        this.isLoading = false;
      }
    },

    getLogType(log) {
      if (log.includes(".ERROR:")) return "error";
      if (log.includes(".INFO:")) return "info";
      if (log.includes(".DEBUG:")) return "debug";
      if (log.includes(".WARNING:")) return "warning";
      return "info";
    },

    getLogClass(log) {
      const type = this.getLogType(log);
      return {
        "text-red-400": type === "error",
        "text-blue-400": type === "info",
        "text-yellow-400": type === "debug",
        "text-orange-400": type === "warning",
      };
    },

    getLogTypeClass(log) {
      const type = this.getLogType(log);
      return {
        "bg-red-500": type === "error",
        "bg-blue-500": type === "info",
        "bg-yellow-500": type === "debug",
        "bg-orange-500": type === "warning",
      };
    },

    formatLog(log) {
      // Remove the log level prefix for cleaner display
      return log.replace(
        /\[\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\] \w+\.(ERROR|INFO|DEBUG|WARNING): /,
        ""
      );
    },

    clearDateFilters() {
      this.dateFilters.from = "";
      this.dateFilters.to = "";
    },

    checkDateFilter(log) {
      if (!this.dateFilters.from && !this.dateFilters.to) {
        return true;
      }

      const logDate = this.extractDateFromLog(log);
      if (!logDate) return true;

      const logTimestamp = logDate.getTime();

      const fromDate = this.dateFilters.from
        ? new Date(this.dateFilters.from).getTime()
        : -Infinity;

      const toDate = this.dateFilters.to
        ? new Date(this.dateFilters.to).setHours(23, 59, 59, 999)
        : Infinity;

      return logTimestamp >= fromDate && logTimestamp <= toDate;
    },

    extractDateFromLog(log) {
      const dateMatch = log.match(/\[(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\]/);
      if (dateMatch && dateMatch[1]) {
        return new Date(dateMatch[1]);
      }
      return null;
    },
  },
  watch: {
    dateFilters: {
      deep: true,
      handler(newVal) {
        if ((newVal.from && newVal.to) || (!newVal.from && !newVal.to)) {
          this.currentPage = 1;
          this.fetchLogs();
        }
      },
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/dashboard.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.bg-teal-900 {
  background-color: #06262d;
}

input::placeholder {
  color: #707070;
}

input {
  color: #707070;
}

input::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

.number-style {
  font-size: 44px;
  line-height: 53px;
  font-weight: 600;
}

.user-label {
  color: #00a388;
}

.custom-button {
  background-color: #00a388;
  transition: background-color 0.2s;
}

.custom-button:hover {
  background-color: #008870;
}

.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

input[type="date"] {
  background-color: #06262d;
  border: 1px solid #204952;
  border-radius: 4px;
  padding: 6px 12px;
  color: white;
  min-width: 150px;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.5;
  cursor: pointer;
  position: static;
  width: 16px;
  height: 16px;
  padding: 0;
  margin-left: 8px;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 0.8;
}

.date-input-container {
  position: relative;
  display: inline-flex;
  align-items: center;
}

input[type="date"] {
  -webkit-appearance: none;
  appearance: none;
  color: white;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin-right: 4px;
}

@-moz-document url-prefix() {
  input[type="date"] {
    padding-right: 32px;
  }
}

@supports (-ms-ime-align: auto) {
  input[type="date"] {
    padding-right: 32px;
  }
}

/* Scrollbar styling */
.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #204952;
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #00a388;
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #008870;
}

.log-type-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 6px;
}

/* Filter checkbox styling */
input[type="checkbox"] {
  accent-color: #00a388;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Hover effect for log entries */
.mb-2:hover {
  background-color: rgba(0, 163, 136, 0.1);
  border-radius: 4px;
}
</style>
